<template>

        <v-row dense>
            <v-col cols="12">
                <div v-show="product.status=='Draft' && !isDashboard" class="font-weight-bold text-center">ADMIN PREVIEW</div>
                <div v-show="product.audience && product.audience=='keystone'" class="text-overline font-weight-bold text-center">*keystone*</div>
            </v-col>
    
            <v-col cols="6">
                <div class="headline">{{ product.name }}</div>
                <div v-show="isBeer" class="font-weight-thin">{{product.style}} - {{product.abv}}% ABV</div>
                <div v-show="isDashboard && isBeer" class="font-weight-bold text-xs-left mt-1">{{ casesOutstanding }}</div>
            </v-col>
 
            <v-col cols="5">
                <v-row dense>
                    <template v-if="hideDateDetail">
                        <v-col cols="10" class="mt-1">
                            <div class="caption text-xs-right"><br>{{shortDate(product.releaseDate) }}</div>
                        </v-col>
                    </template>
                    <template v-else>
                        <v-col cols="10" v-bind:mt-1="!ordersClosed" v-bind:mt-3="ordersClosed">
                            <div v-if="ordersClosed" class="caption text-xs-right">Orders closed</div>
                            <div v-else class="caption text-xs-right">Orders OPEN until <br>{{shortDate(product.orderEnd) }}</div>
                        </v-col>
                        <v-col v-show="!ordersClosed" cols="1" class="mt-2 pt-1 ml-1">
                            <div class="caption text-xs-right"><v-icon v-bind:color="iconColor(product.orderEnd)">access_time</v-icon></div>
                        </v-col>
                        <v-col cols="10" class="mt-2">
                            <div v-if="ordersClosed" class="caption text-xs-right">
                                <div v-if="pickupsClosed" class="caption text-xs-right">Pickups closed</div>
                                <div v-else class="caption text-xs-right">Pickups end {{shortDate(product.pickupEnd)}}</div>
                            </div>
                            <div v-else class="caption text-xs-right">Pickup 
                                {{shortDate(product.pickupStart) + " - " + shortDate(product.pickupEnd)}}
                            </div>
                        </v-col>  
                        <v-col v-show="!pickupsClosed" cols="1" class="mt-2 pt-1 ml-1">
                            <div class="caption text-xs-right"><v-icon v-bind:color="iconColor(product.pickupEnd)">event</v-icon></div>
                        </v-col> 
                    </template>
                </v-row>
            </v-col>
        </v-row>
 
</template>

<script>
import moment from 'moment-timezone'
export default {
    props: {
        product: {type: Object, required: true},
        hideDateDetail: {type: Boolean, required: false},
        isDashboard: {type: Boolean, required: false}
    },
    computed: {
        ordersClosed () { return this.closed(this.product.orderEnd)},
        pickupsClosed () { return this.closed(this.product.pickupEnd)},
        isBeer () { return this.product.category != "Membership"},
        casesOutstanding () {
            if(this.product.variations)
                return this.product.variations[0].casesRemaining + " cases outstanding"
            else return ""
        }
    },
    methods: {
        closed (dateStr) {
            let diff = moment(dateStr, "YYYY-MM-DD @HH:mm").diff(moment(), "minutes")
            return diff < 0
        },
        shortDate (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").calendar()
        },
        iconColor (dateStr) {
            let diff = moment(dateStr, "YYYY-MM-DD @HH:mm").diff(moment(), "hours")
            if(diff < 24)
                return "red"
            else return ""
        },
    }
}
</script>
